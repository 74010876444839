
<template>
  <div id="conform">
    <p class="uk-text-large uk-text-left" style="color: #000000;">
      If you would like to discuss a project, or if you have any questions
      about my qualifications,
      I would like to hear from you! You can reach me at
      <a href="mailto:wobray@vquintus.com">wobray@vquintus.com</a>
    </p>
  </div>
</template>

<script>
/*
NOTE: No point in creating a contact form without a valid
server certificate.
*/
// import axios from 'axios';

/*
name, email, message | error (clina, clima, clime)
*/

  export default {
    data() {
    }
  };
</script>
